import http from '@/utils/request.js'

export function getList(data) {
    return http({
        url: '/management/select',
        method: "post",
        data
    })
}

export function getListUpdate(params) {
    return http({
        url: '/management/update',
        method: "get",
        params
    })
}

export function login(params) {
    return http({
        url: '/oauth/login',
        method: "get",
        params
    })
}