// 导入axios
import axios from 'axios'
// 导入element的message组件
import { Message } from 'element-ui'

const domainStr = location.host; //获取请求域名及端口号
const instance = axios.create({
    baseURL: domainStr.includes('localhost') ? "/api" : 'https://bkbkgs.cn/course/',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

// http request 请求拦截器
instance.interceptors.request.use(
    config => {
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// http response 响应拦截器
instance.interceptors.response.use(
    response => {

        return response.data
    },
    error => {
        console.log(error)

        Message({
            message: error.response.data.message || '网络异常',
            type: 'error'
        });
        return Promise.reject(error.response.data)
    }
)

export default instance

