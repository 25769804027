<template>
  <div class="page">
    <div class="topNav color">
      聊城一对一<span class="color1">家教辅导</span>后台管理系统
      <div class="hr"></div>
    </div>
    <div class="option">
      <div class="breadcrumb">
        <el-button
          style="background: #07a8e3; color: #fff; border-radius: 4px"
          size="mini"
          >报名管理系统</el-button
        >
        <i class="el-icon-arrow-right"></i>
        <span>学生信息检索</span>
      </div>

      <el-form class="flex j-sb flex-warp" ref="form" :model="form">
        <el-form-item label="学生年级">
          <el-select
            class="inputItem"
            v-model="form.grade"
            placeholder="请选择"
          >
            <el-option
              v-for="item in gradeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系号码">
          <el-input
            placeholder="请输入"
            maxlength="11"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="抽奖日期">
          <el-date-picker
            class="inputItem"
            v-model="lotterytime"
            type="date"
            value-format="yyyy/MM/dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="获得奖品">
          <el-input
            placeholder="请输入"
            maxlength="30"
            v-model="form.prize"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="btns">
        <el-button
          class="background"
          size="medium"
          type="primary"
          @click="search"
          >查询</el-button
        >
        <el-button @click="clear" size="medium">清空</el-button>
      </div>
    </div>

    <div class="table">
      <div class="flex j-sb">
        <div class="table_title">全部抽奖人统计</div>
        <!-- <el-button class="color" type="text">下载表单</el-button> -->
      </div>
      <div class="hr"></div>

      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%; min-height: 400px"
      >
        <el-table-column align="center" prop="id" label="序号">
        </el-table-column>
        <!-- <el-table-column align="center" prop="username" label="抽奖人">
        </el-table-column> -->

        <el-table-column align="center" prop="address" label="学生地址">
        </el-table-column>

        <el-table-column align="center" prop="grade" label="学生年级">
          <template slot-scope="scope">
            <div>
              {{ isGrade(scope.row.grade) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="phone" label="家长电话">
        </el-table-column>

        <el-table-column align="center" prop="contact" label="联系人">
        </el-table-column>

        <el-table-column align="center" prop="lotterytime" label="抽奖时间">
        </el-table-column>

        <el-table-column align="center" prop="prize" label="获得奖品">
        </el-table-column>

        <el-table-column align="center" prop="status" label="兑奖">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == '发放奖品'"
              type="text"
              @click="fajp(scope.row)"
              >发放奖品
            </el-button>
            <el-button v-else type="text" disabled>已发放</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="query.pageSize"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
  import { getList, getListUpdate } from "@/api";
  export default {
    data() {
      return {
        loading: false,
        tableData: [],
        lotterytime: "",
        query: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        form: {
          grade: "",
          phone: "",
          prize: "",
        },
        genderList: [
          {
            value: "0",
            label: "全部",
          },
          {
            value: "1",
            label: "男生",
          },
          {
            value: "2",
            label: "女生",
          },
        ],
        gradeList: [
          {
            value: "",
            label: "全部",
          },
          {
            value: "1",
            label: "小学生",
          },
          {
            value: "2",
            label: "初中生",
          },
          {
            value: "3",
            label: "高中生",
          },
        ],
      };
    },
    created() {
      let isLogin = sessionStorage.getItem("token");
      if (isLogin) {
        this.search();
      } else {
        this.$router.replace({
          path: "/login",
        });
      }
    },
    methods: {
      isGrade(type) {
        let obj = this.gradeList.find((v) => {
          return v.value == type;
        });
        if (obj) {
          return obj.label;
        }
        return "";
      },
      fajp(row) {
        getListUpdate({ id: row.id }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "发放成功",
              type: "success",
            });
            this.search();
          }
        });
      },
      getList() {
        this.loading = true;
        let params = {
          // ...this.query,
          ...this.form,
          lotterytime: this.lotterytime,
        };
        getList(params).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            this.loading = false;
          }
        });
      },

      search() {
        this.query.page = 1;
        this.getList();
      },
      handleCurrentChange(val) {
        this.query.page = val;
        this.getList();
      },
      clear() {
        this.form = {
          grade: "",
          phone: "",
          prize: "",
        };
        this.lotterytime = "";
        this.search();
      },
    },
  };
</script>
<style lang="less" scoped>
.el-pagination {
  text-align: right;
  margin-top: 10px;
}
.el-form-item {
  display: flex;
}
.page {
  background: rgba(250, 251, 252, 1);
  .topNav {
    width: 100%;
    height: 92px;
    background: #ffffff;
    font-family: Source Han Sans CN;
    font-weight: 600;
    font-size: 32px;
    color: #07a8e3;
    line-height: 36px;
    line-height: 92px;
    padding-left: 51px;
    box-sizing: border-box;
    .hr {
      width: 900px;
      height: 1px;
      background: #2d7ccc;
    }
  }
  .option {
    width: auto;
    // height: 190px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(146, 211, 250, 0.1);
    border-radius: 4px;
    margin: 20px 80px 20px 160px;
    box-sizing: border-box;
    padding: 12px 62px 17px;
    .breadcrumb {
      display: flex;
      align-items: center;
      margin-bottom: 46px;
      .el-icon-arrow-right {
        font-size: 10px;
        margin: 0 5px;
      }
      span {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }

    .inputItem {
      width: 204px;
      // margin-left: 40px;
      // margin-right: 50px;
    }
    .btns {
      text-align: center;
    }
  }
  .table {
    margin: 20px 80px 20px 160px;
    background: #ffffff;
    padding: 42px 49px;
    .table_title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 21px;
      color: #282828;
      margin-bottom: 26px;
    }
    .hr {
      width: 130px;
      height: 2px;
      background: #2d7ccc;
      // margin-top: 16px;
    }
  }
}
</style>